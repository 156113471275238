import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import axios from 'axios'

Vue.use(Vuex)
Vue.use(VueCookies)

export default new Vuex.Store({
  state: {
    authError: '',
    isLoggedIn: !!Vue.$cookies.isKey('user_data'),
    token: Vue.$cookies.get('user_token'),
    user: Vue.$cookies.get('user_data')
  },
  getters: {
    isLoggedIn: function (state) {
      return state.isLoggedIn
    },
    user: function (state) {
      return state.user
    },
    authError: function (state) {
      return state.authError
    }
  },
  mutations: {
    AUTH_ERROR: function (state, error) {
      state.authError = error
    },
    LOGGED_IN: function (state, payload) {
      state.isLoggedIn = true
      state.token = payload.token
      state.user = payload.user
      state.loginError = ''
    },
    LOGGED_OUT: function (state) {
      state.isLoggedIn = false
      state.token = null
      state.user = null
    }
  },
  actions: {
    signUpUser: function ({ commit }, payload) {
      return new Promise(function (resolve) {
        axios.post('/api/users/signup', {
          username: payload.username,
          password: payload.password
        }).then(function (response) {
          Vue.$cookies.set('user_token', response.data.token, -1)
          Vue.$cookies.set('user_data', response.data.user, -1)
          var payload = { token: response.data.token, user: response.data.user }
          commit('LOGGED_IN', payload)
          resolve(response)
        }).catch(function (error) {
          commit('AUTH_ERROR', error.response.data.error)
        })
      })
    },
    logInUser: function ({ commit }, payload) {
      return new Promise(function (resolve) {
        axios.post('/api/users/login', {
          username: payload.username,
          password: payload.password
        }).then(function (response) {
          Vue.$cookies.set('user_token', response.data.token, -1)
          Vue.$cookies.set('user_data', response.data.user, -1)
          var payload = { token: response.data.token, user: response.data.user }
          commit('LOGGED_IN', payload)
          resolve(response)
        }).catch(function (error) {
          commit('AUTH_ERROR', error.response.data.error)
        })
      })
    },
    logOutUser: function ({ commit }) {
      return new Promise(function (resolve) {
        axios.defaults.headers.common.Authorization = Vue.$cookies.get('user_token')
        axios.post('/api/users/logout').then(function (response) {
          Vue.$cookies.remove('user_token')
          Vue.$cookies.remove('user_data')
          commit('LOGGED_OUT')
          resolve(response)
        }).catch(function (error) {
          console.log(error)
        })
      })
    },
    logOutUserEverywhere: function ({ commit }) {
      return new Promise(function (resolve) {
        axios.defaults.headers.common.Authorization = Vue.$cookies.get('user_token')
        axios.post('/api/users/logout-all').then(function (response) {
          Vue.$cookies.remove('user_token')
          Vue.$cookies.remove('user_data')
          commit('LOGGED_OUT')
          resolve(response)
        }).catch(function (error) {
          console.log(error)
        })
      })
    }
  }
})
