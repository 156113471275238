import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/404.vue'),
    meta: {
      title: 'Propstep Onboard',
      authRedirect: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Log in | Propstep Onboard',
      authRedirect: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      title: 'Companies | Propstep Onboard',
      requiresAuth: true
    }
  },
  {
    path: '/admin/:company',
    name: 'Company',
    component: () => import('../views/Company.vue'),
    meta: {
      title: 'Propstep Onboard',
      requiresAuth: true
    }
  },
  {
    path: '/:id',
    name: 'Project',
    component: () => import('../views/Project.vue'),
    meta: {
      title: 'Propstep Onboard'
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      title: 'Propstep Onboard'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) {
  document.title = to.meta.title || 'Propstep Onboard'
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
    } else {
      next('/login')
    }
  } else if (to.matched.some(record => record.meta.authRedirect)) {
    if (store.getters.isLoggedIn) {
      next('/admin')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
