<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

::selection {
  background: $green-50;
  color: white;
}

html, body, div, span, ol, ul, li, img,
h1, h2, h3, h4, h5, h6, p, small, strong, a,
form, fieldset, label, input, textarea, select, button {
  font: inherit;
  font-size: 100%;
  appearance: none;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}

body {
  background: $gray-10;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: $green-80;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a, a:visited {
  color: inherit;
  text-decoration: none;
}

.heading-r {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  @media only screen and (min-width: $large) {
    font-size: 44px;
    line-height: 56px;
  }
}

.heading-xs {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  @media only screen and (min-width: $large) {
    font-size: 24px;
    line-height: 32px;
  }
}

.heading-xxs {
  font-size: 16px;
  font-weight: 600;
  @media only screen and (min-width: $large) {
    font-size: 20px;
  }
}

.button {
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  &-primary {
    background: $green-50;
    border: 1px solid $green-50;
    color: white !important;
    &:hover {
      background: $green-40;
    }
    &:active {
      background: $green-60;
    }
  }
  &-secondary {
    background: transparent;
    border: 1px solid $green-80;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
  }
  &-danger {
    background: $red-50;
    color: white;
    &:hover {
      background: $red-40;
    }
    &:active {
      background: $red-60;
    }
  }
  &-white {
    color: white;
    border-color: white;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
  }
  &-small {
    font-size: 12px;
    line-height: 20px;
    padding: 8px 16px;
    border-radius: 4px;
  }
  img {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 8px;
  }
  &:disabled {
    background: $gray-50;
    border-color: $gray-50;
    cursor: default;
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"],
textarea {
  width: 100%;
  background: white;
  border: 1px solid $gray-40;
  border-radius: 8px;
  display: block;
  &:focus {
    border-color: $green-50;
  }
  &::placeholder {
    color: $gray-50;
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"] {
  max-width: 400px;
  height: 54px;
  padding: 0 16px;
}

input[type="file"] {
  padding: 12px 16px;
}

textarea {
  min-height: 88px;
  padding: 16px;
  resize: vertical;
  &.big {
    min-height: 364px;
  }
}

.modal {
  width: 100%;
  height: 100vh;
  background: rgba(black, 0.4);
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  &-box {
    width: 100%;
    max-width: 440px;
    text-align: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 16px 64px rgba(black, 0.2);
    padding: 64px 24px;
    position: relative;
    @media only screen and (min-width: $large) {
      padding: 64px 48px;
    }
    h2 {
      margin-bottom: 32px;
    }
    p {
      margin-bottom: 32px;
      strong {
        font-weight: 600;
      }
    }
    .button {
      width: 100%;
    }
    form {
      input {
        margin-bottom: 16px;
      }
      button {
        margin-top: 16px;
      }
    }
    &-warning {
      background: $orange-10;
      border-radius: 8px;
      padding: 24px;
      margin-bottom: 32px;
      p {
        margin-bottom: 0;
      }
    }
    &-close {
      width: 32px;
      height: 32px;
      background-image: url("assets/icon-close.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      border-radius: 100%;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      &:hover {
        background-color: $gray-10;
      }
    }
  }
}

.admin {
  max-width: 1240px;
  margin: 96px auto;
  padding: 0 20px;
  &-empty {
    text-align: center;
    margin-top: 64px;
    img {
      width: 300px;
      height: 200px;
      margin: 0 auto;
    }
    p {
      margin-top: 8px;
      color: $gray-50;
    }
  }
  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    @media only screen and (min-width: $small) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &-return {
      width: 48px;
      height: 48px;
      background-image: url("assets/icon-return.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      margin-top: -4px;
      margin-right: 16px;
      cursor: pointer;
      &:hover {
        background-color: $gray-20;
      }
    }
    button {
      @media only screen and (max-width: $small) {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
  &-grid {
    display: flex;
    flex-wrap: wrap;
    &-item {
      flex: 0 0 100%;
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba($green-80, 8%);
      border: 1px solid white;
      margin-bottom: 24px;
      position: relative;
      @media only screen and (min-width: $small) {
        flex: 0 0 calc(100% / 3 - 16px);
        margin-right: 24px;
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
      &:hover {
        border-color: $green-50;
      }
      a {
        display: block;
        padding: 64px 24px 24px 24px;
        h3 {
          margin-bottom: 4px;
        }
      }
      &-ellipsis {
        width: 32px;
        height: 32px;
        background-image: url("assets/icon-ellipsis.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        border-radius: 100%;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        &:hover {
          background-color: $gray-10;
        }
      }
    }
  }
  &-list {
    &-item {
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba($green-80, 8%);
      border: 1px solid white;
      padding: 32px;
      margin-bottom: 24px;
      position: relative;
      @media only screen and (min-width: $small) {
        display: flex;
        justify-content: space-between;
      }
      &:hover {
        border-color: $green-50;
      }
      &-info {
        display: flex;
        align-items: center;
      }
      &-link {
        display: flex;
        align-items: center;
        margin-right: 32px;
        a {
          margin-right: 4px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &-copy,
      &-ellipsis {
        width: 32px;
        height: 32px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        border-radius: 100%;
        display: inline-block;
        cursor: pointer;
        &:hover {
          background-color: $gray-10;
        }
      }
      &-copy {
        background-image: url("assets/icon-copy.svg");
        position: relative;
        > div {
          font-size: 12px;
          font-weight: 600;
          color: white;
          background: $green-80;
          border-radius: 4px;
          padding: 4px 12px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          user-select: none;
          animation: fadeup 1s ease-out;
          &:before {
            content: "Copied";
          }
        }
      }
      &-ellipsis {
        background-image: url("assets/icon-ellipsis.svg");
      }
    }
  }
}

.loading {
  width: 100%;
  height: calc(100vh - 86px);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    opacity: 0.2;
  }
}

@keyframes fadeup {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -32px;
    opacity: 0;
  }
}
</style>
